.App {
  text-align: center;
}
#root {
  background:#f7f6f6;
}

.MuiDataGrid-cell {
  font-size: 13px;
}
